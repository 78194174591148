<kt-portlet class="shared-component">
  <!-- <kt-portlet-header *ngIf="withSubmit" [sticky]="true" [title]="getComponentTitle()" [class]="'kt-portlet__head--lg'" [viewLoading$]="loading$">
	</kt-portlet-header> -->
  <kt-portlet-body>
    <div *ngIf="product">
      <form [formGroup]="productForm" class="kt-form kt-form--group-seperator-dashed">
        <kt-alert
          *ngIf="hasFormErrors"
          type="warn"
          [showCloseButton]="true"
          [duration]="10000"
          (close)="onAlertClose($event)"
        >
          {{ "GENERAL.FORM_ERROR_MESSAGE" | translate }}
        </kt-alert>
        <div class="kt-form__section kt-form__section--first">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 kt-margin-bottom-10-mobile">
                  <div class="input-label mb-3">
                    <label class="mat-input-label">{{
                      "CUSTOMERS.ADD.FIELDS.CUSTOMER_TYPE.PLACEHOLDER" | translate
                    }}</label>
                  </div>
                  <mat-radio-group formControlName="customer_type">
                    <mat-radio-button
                      *ngFor="let customerType of customerTypes"
                      [value]="customerType.code"
                      (change)="customerTypeChanged($event)"
                      [checked]="customerType.code === productForm.controls.customer_type.value"
                    >
                      {{ customerType.name }}
                    </mat-radio-button>
                  </mat-radio-group>
                  <div
                    class="error-msg mt-2"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['customer_type_id']
                    }"
                  >
                    <mat-error
                      *ngIf="
                        productForm.controls.customer_type.touched &&
                        productForm.controls.customer_type.hasError('required')
                      "
                    >
                      <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.CUSTOMER_TYPE.ERROR_MSG' | translate"></span>
                    </mat-error>
                    <mat-error *ngIf="errorMsgs['customer_type_id']"> {{ errorMsgs["customer_type_id"][0] }}</mat-error>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div [ngClass]="columns == 2 ? 'col-md-12' : 'col-md-6'">
              <div class="kt-form__group">
                <div class="row" *ngIf="productForm.controls.customer_type.value == customerTypesEnums.CORPORATE">
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label"
                      >{{ "CUSTOMERS.ADD.FIELDS.COMPANY_NAME.PLACEHOLDER" | translate }}
                    </label>
                    <mat-form-field
                      class="example-full-width searchable"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['company_name']
                      }"
                    >
                      <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                      <input
                        type="text"
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.COMPANY_NAME.PLACEHOLDER' | translate }}"
                        aria-label="Number"
                        matInput
                        formControlName="company_name"
                        [matAutocomplete]="companyAutoComplete"
                      />
                      <mat-autocomplete
                        #companyAutoComplete="matAutocomplete"
                        [displayWith]="companyDisplayFn"
                        (optionSelected)="companySelected($event)"
                      >
                        <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
                          {{ company.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="productForm.controls.company_name.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.COMPANY_NAME.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['company_name']">
                      {{ errorMsgs["company_name"][0] }}
                    </mat-error>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-6 kt-margin-bottom-10-mobile"
                    *ngIf="productForm.controls.customer_type.value === customerTypesEnums.PRIVATE"
                  >
                    <label class="mat-input-label">{{ "CUSTOMERS.ADD.FIELDS.NAME.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['name']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.NAME.PLACEHOLDER' | translate }}"
                        formControlName="customer_name"
                        type="text"
                      />
                      <mat-error *ngIf="productForm.controls.customer_name.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.NAME.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['name']">{{ errorMsgs["name"][0] }}</mat-error>
                  </div>
                  <div
                    class="col-md-6 kt-margin-bottom-10-mobile"
                    *ngIf="productForm.controls.customer_type.value === customerTypesEnums.CORPORATE"
                  >
                    <label class="mat-input-label">{{
                      "CUSTOMERS.ADD.FIELDS.NAME.COMPANY_CONTACT_NAME" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['company_contact_name']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.NAME.PLACEHOLDER' | translate }}"
                        formControlName="company_contact_name"
                        type="text"
                      />
                      <mat-error *ngIf="productForm.controls.company_contact_name.hasError('required')">
                        <span
                          [innerHTML]="'CUSTOMERS.ADD.FIELDS.NAME.ERROR_MSG_COMPANY_CONTACT_NAME' | translate"
                        ></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['company_contact_name']">
                      {{ errorMsgs["company_contact_name"][0] }}
                    </mat-error>
                  </div>
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "CUSTOMERS.ADD.FIELDS.ID_NUMBER.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['id_number']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.ID_NUMBER.PLACEHOLDER' | translate }}"
                        formControlName="id_number"
                        type="text"
                        (focusout)="getCustomerByIdNumber()"
                      />
                      <mat-error *ngIf="productForm.controls.id_number.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.ID_NUMBER.ERROR_MSG' | translate"></span>
                      </mat-error>
                      <mat-error *ngIf="productForm.controls.id_number.hasError('maxlength')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.ID_NUMBER.MAX_LENGTH' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['id_number']">{{ errorMsgs["id_number"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "CUSTOMERS.ADD.FIELDS.BIRTHDAY.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['birthdate']
                      }"
                    >
                      <input
                        [max]="today"
                        matInput
                        [matDatepicker]="picker"
                        formControlName="birthdate"
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.BIRTHDAY.PLACEHOLDER' | translate }}"
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-error
                        *ngIf="
                          productForm.get('birthdate').hasError('matDatepickerMax') ||
                          productForm.controls.birthdate.errors
                        "
                      >
                        {{ "CUSTOMERS.ADD.FIELDS.BIRTHDAY.ERROR_MSG" | translate }}
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['birthdate']">{{ errorMsgs["birthdate"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "CUSTOMERS.ADD.FIELDS.ADDRESS.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['address']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.ADDRESS.PLACEHOLDER' | translate }}"
                        formControlName="address"
                        type="text"
                      />
                      <mat-error *ngIf="productForm.controls.address.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.ADDRESS.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['address']">{{ errorMsgs["address"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "CUSTOMERS.ADD.FIELDS.POSTAL_CODE.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['postal_code']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.POSTAL_CODE.PLACEHOLDER' | translate }}"
                        formControlName="postal_code"
                        type="text"
                        (focusout)="loadCityName()"
                      />
                      <mat-error *ngIf="productForm.controls.postal_code.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.POSTAL_CODE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['postal_code']">{{ errorMsgs["postal_code"][0] }}</mat-error>
                  </div>
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "CUSTOMERS.ADD.FIELDS.CITY.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['city']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.CITY.PLACEHOLDER' | translate }}"
                        formControlName="city"
                        type="text"
                      />
                      <mat-error *ngIf="productForm.controls.city.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.CITY.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['city']">{{ errorMsgs["city"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "CUSTOMERS.ADD.FIELDS.PHONE.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['phone']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.PHONE.PLACEHOLDER' | translate }}"
                        formControlName="phone"
                        type="text"
                      />
                      <mat-error *ngIf="productForm.controls.phone.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.PHONE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['phone']">{{ errorMsgs["phone"][0] }}</mat-error>
                  </div>
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "CUSTOMERS.ADD.FIELDS.EMAIL.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['email']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.EMAIL.PLACEHOLDER' | translate }}"
                        formControlName="email"
                        type="email"
                      />
                      <mat-error *ngIf="productForm.controls.email.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.EMAIL.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['email']">{{ errorMsgs["email"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <!-- customer -->
                  <div
                    *ngIf="permissions?.view_users && permissions?.assign_customer_account_manager"
                    class="col-md-6 kt-margin-bottom-10-mobile"
                  >
                    <label class="mat-input-label">{{
                      "CUSTOMERS.ADD.FIELDS.ACCOUNT_MANAGER.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="example-full-width searchable"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['account_manger_id']
                      }"
                    >
                      <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                      <input
                        type="text"
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.ACCOUNT_MANAGER.PLACEHOLDER' | translate }}"
                        aria-label="Number"
                        matInput
                        formControlName="account_manger"
                        [matAutocomplete]="auto"
                        (click)="getCustomerByScrollEvent()"
                      />
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="managerDisplayFn">
                        <div>
                          <mat-spinner [diameter]="20" *ngIf="isLoadingResults"></mat-spinner>
                        </div>
                        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                          {{ user.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="productForm.controls.account_manger.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.ACCOUNT_MANAGER.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['account_manger_id']">
                      {{ errorMsgs["account_manger_id"][0] }}
                    </mat-error>
                  </div>
                  <!-- customer -->
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "CUSTOMERS.ADD.FIELDS.PAYMENT_TERM.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['payment_term_id']
                      }"
                    >
                      <mat-select
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.PAYMENT_TERM.PLACEHOLDER' | translate }}"
                        formControlName="payment_term_id"
                      >
                        <mat-option [value]="paymentTerm.id" *ngFor="let paymentTerm of paymentTerms"
                          >{{ paymentTerm.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="productForm.controls.payment_term_id.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.PAYMENT_TERM.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['payment_term_id']"> {{ errorMsgs["payment_term_id"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "CUSTOMERS.ADD.FIELDS.BANK_CODE.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['bank_code']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.BANK_CODE.PLACEHOLDER' | translate }}"
                        formControlName="bank_code"
                        type="text"
                        maxlength="4"
                      />
                      <mat-error *ngIf="productForm.controls.bank_code.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.BANK_CODE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['bank_code']">{{ errorMsgs["bank_code"][0] }}</mat-error>
                  </div>
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "CUSTOMERS.ADD.FIELDS.BANK_ACCOUNT_NUMBER.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['bank_account_number']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.BANK_ACCOUNT_NUMBER.PLACEHOLDER' | translate }}"
                        formControlName="bank_account_number"
                        type="text"
                        pattern="\d*"
                        maxlength="11"
                      />
                      <mat-error *ngIf="productForm.controls.bank_account_number.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.BANK_ACCOUNT_NUMBER.ERROR_MSG' | translate"></span>
                      </mat-error>
                      <mat-error *ngIf="productForm.controls.bank_account_number.hasError('pattern')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.BANK_ACCOUNT_NUMBER.PATTERN' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['bank_account_number']">
                      {{ errorMsgs["bank_account_number"][0] }}
                    </mat-error>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "CUSTOMERS.ADD.FIELDS.CURRENCY.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      class="example-full-width searchable"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['currency']
                      }"
                    >
                      <span class="kt-svg-icon search-icon" [inlineSVG]="'/assets/media/icons/magnify.svg'"></span>
                      <input
                        type="text"
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.CURRENCY.PLACEHOLDER' | translate }}"
                        aria-label="Number"
                        matInput
                        formControlName="currency"
                        [matAutocomplete]="supplierAutoComplete"
                      />
                      <mat-autocomplete #supplierAutoComplete="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let cur of filteredCurrencies | async" [value]="cur">
                          {{ cur.code }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="productForm.controls.currency.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.CURRENCY.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['currency']">{{ errorMsgs["currency"][0] }}</mat-error>
                  </div>
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "CUSTOMERS.ADD.FIELDS.COUNTRY.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['country']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.COUNTRY.PLACEHOLDER' | translate }}"
                        formControlName="country"
                        type="text"
                      />
                      <mat-error *ngIf="productForm.controls.country.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.COUNTRY.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['country']"> {{ errorMsgs["country"][0] }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "CUSTOMERS.ADD.FIELDS.RISK_CLASS.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="example-full-width searchable"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['risk_class_id']
                      }"
                    >
                      <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                      <input
                        type="text"
                        placeholder="{{ 'CUSTOMERS.ADD.FIELDS.RISK_CLASS.PLACEHOLDER' | translate }}"
                        aria-label="Number"
                        matInput
                        formControlName="risk_class_id"
                        [matAutocomplete]="riskClassAutoComplete"
                      />
                      <mat-autocomplete #riskClassAutoComplete="matAutocomplete" [displayWith]="riskClassDisplayFn">
                        <mat-option *ngFor="let riskClass of filteredRiskClassifications | async" [value]="riskClass">
                          {{ riskClass.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="productForm.controls.risk_class_id.hasError('required')">
                        <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.RISK_CLASS.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['risk_class_id']">
                      {{ errorMsgs["risk_class_id"][0] }}
                    </mat-error>
                  </div>
                  <ng-container *ngIf="withSubmit">
                    <div class="col-md-6 kt-margin-bottom-10-mobile">
                      <label class="mat-input-label">{{
                        "CUSTOMERS.ADD.FIELDS.EAN_NUMBER.PLACEHOLDER" | translate
                      }}</label>
                      <mat-form-field
                        class="mat-form-field-fluid"
                        [ngClass]="{
                          'mat-form-field-invalid': errorMsgs['ean_number']
                        }"
                      >
                        <input
                          matInput
                          type="text"
                          inputmode="number"
                          oninput="this.value = this.value.replace(/[^\d]+/g, '')"
                          placeholder="{{ 'CUSTOMERS.ADD.FIELDS.EAN_NUMBER.PLACEHOLDER' | translate }}"
                          formControlName="ean_number"
                        />
                        <mat-error *ngIf="productForm.controls.ean_number.hasError('required')">
                          <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.EAN_NUMBER.ERROR_MSG' | translate"></span>
                        </mat-error>
                        <mat-error *ngIf="productForm.controls.ean_number.hasError('minlength')">
                          <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.EAN_NUMBER.LENGTH_ERROR' | translate"></span>
                        </mat-error>
                        <mat-error *ngIf="productForm.controls.ean_number.hasError('maxlength')">
                          <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.EAN_NUMBER.LENGTH_ERROR' | translate"></span>
                        </mat-error>
                      </mat-form-field>
                      <mat-error *ngIf="errorMsgs['ean_number']">
                        <span> {{ errorMsgs["ean_number"][0] }} </span>
                      </mat-error>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </kt-portlet-body>
  <kt-portlet-footer *ngIf="withSubmit" class="entity__footer">
    <div *ngIf="product" class="kt-form__actions kt-form__actions--sm">
      <button *ngIf="isCancelEnabled" mat-button (click)="onCloseCustomerModal()">
        {{ "GENERAL.CANCEL" | translate }}
      </button>

      <button
        *ngIf="!isReadonly"
        (click)="onSubmit()"
        class="btn btn-primary"
        color="primary"
        mat-raised-button
        matTooltip="{{ 'GENERAL.SAVE' | translate }}"
      >
        {{ id ? ("CUSTOMERS.ADD.EDIT_TITLE" | translate) : ("CUSTOMERS.ADD.ADD_TITLE" | translate) }}
      </button>
    </div>
  </kt-portlet-footer>
</kt-portlet>

<!-- <mat-form-field>
  <mat-select #auto (opened)="getCustomerByScrollEvent()">
    <div><mat-spinner [diameter]="20" *ngIf="isLoadingResults"></mat-spinner></div>

    <mat-option *ngFor="let dr of filteredUsers | async">
      {{ dr.name }}
    </mat-option>
  </mat-select>
</mat-form-field> -->
