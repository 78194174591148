import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: "safeHtml",
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string): SafeHtml {
    if (!html) return "";
    const cleanHtml = html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "");
    const withoutDangerousAttrs = cleanHtml.replace(/on\w+="[^"]*"/g, "");
    const withoutJsUrls = withoutDangerousAttrs.replace(/javascript:[^"']*/g, "");
    const SafeContent = withoutJsUrls !== "" ? withoutJsUrls : "<p style='color:red'>Content is not safe</p>";
    return this.sanitizer.bypassSecurityTrustHtml(SafeContent);
  }
}
