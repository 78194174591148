// Angular
import { animate, state, style, transition, trigger } from "@angular/animations";
import { DecimalPipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
// CRUD
import { HttpClient } from "@angular/common/http";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { fromEvent, merge, Observable, of as observableOf, Subscription } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, tap } from "rxjs/operators";
import { LayoutUtilsService, MessageType, TypesUtilsService } from "../../../core/_base/crud";
import { TransactionBookDialogComponent } from "./dialogs/transaction-book-dialog/transaction-book-dialog.component";
import { TransactionEditDialogComponent } from "./dialogs/transaction-edit-dialog/transaction-edit-dialog.component";
import { TransactionModel } from "./transaction.model";
// Tranlsation
import { MatButton } from "@angular/material/button";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import { ITransactionRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { GlobalUtilsService } from "../../../core/_base/crud/utils/global-utils.service";
import { currentUser } from "../../../core/auth";
import { MAT_DATA_TABLES_TRANSACTIONS } from "../../../core/constants/local-storage-constants";
import { AppState } from "../../../core/reducers";
import { TransactionEntityTypes } from "../enums/transaction/transactionEntityTypes";
import { TransactionTypes, transactionTypesLabels } from "../enums/transaction/transactionTypes";
import { TransactionsService } from "./transactions.service";
import { DocumentService } from "../document.service";
import { SelectionModel } from "@angular/cdk/collections";
import { ContractOfferStatus } from "../enums/entity/contractOfferStatus";
import { TransactionImportDialogComponent } from "./dialogs/transaction-import-dialog/transaction-import-dialog.component";

@Component({
  selector: "kt-transactions-list",
  templateUrl: "./transactions-list.component.html",
  styleUrls: ["./transactions-list.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*", marginBottom: "10px" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionListComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  displayedColumns: string[] = [
    "expand",
    "transaction_set_id",
    "date",
    "transaction_type",
    "transaction_text",
    "amount_cents",
    "vat_code",
    "account_number",
    "is_booked",
    "created_at",
    "actions",
  ];
  data: TransactionModel[] = [];
  expandedElement: TransactionModel | null;
  TransactionEntityTypes = TransactionEntityTypes;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("searchButton") searchButton: MatButton;
  filteredContracts: Observable<any[]>;
  filteredVehicles: Observable<any[]>;
  noContractsFound: boolean = false;
  noVehiclesFound: boolean = false;
  lastSavedContractId: any;
  unloadWindow: boolean = false;
  transactionTypes = TransactionTypes;
  transactionTypesLabels = transactionTypesLabels;
  selectedFilters = {};
  filters = {
    is_booked: "all",
    from_date: null,
    to_date: null,
    contract: null,
    vehicle: null,
    type: null,
  };
  filtersState; // The currently saved filters in localStorage
  userId: any; //Logged in user id
  existingPagination: number;
  contract_id: any;
  vehicle_id: any;
  from = null;
  selection = new SelectionModel<TransactionModel>(true, []);
  isbookedTransactionSelected: boolean = true;
  isUnbookedTransactionSelected: boolean = true;
  isDifferentTypesSelected: boolean = false;
  contractStatus: ContractOfferStatus;
  isCancelled: boolean = false;

  constructor(
    private _httpClient: HttpClient,
    private productsService: TransactionsService,
    protected layoutUtilsService: LayoutUtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TransactionListComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private decimalPipe: DecimalPipe,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private translate: TranslateService,
    private typesUtilsService: TypesUtilsService,
    private documentService: DocumentService
  ) {}

  ngOnInit() {
    const routeSubscription = this.route.queryParams.subscribe((queryParams) => {
      // If component is navigated to using routing i.e. `/contracts/transactions?contract_id=48`
      if (queryParams["contract_id"]) {
        this.contract_id = queryParams["contract_id"];
      }
      if (queryParams["vehicle_id"]) {
        this.vehicle_id = queryParams["vehicle_id"];
      }
    });
    this.subscriptions.push(routeSubscription);

    if (this.dialogData.contract_id) {
      // If component is opend as modal
      this.contract_id = this.dialogData.contract_id;
      this.displayedColumns = [
        "expand",
        "transaction_set_id",
        "date",
        "transaction_type",
        "transaction_text",
        "amount_cents",
        "vat_code",
        "account_number",
        "is_booked",
        "contract_value",
        "created_at",
        "actions",
      ];
    }
    if (this.dialogData.vehicle_id) {
      // If component is opend as modal
      this.vehicle_id = this.dialogData.vehicle_id;
      this.displayedColumns = [
        "expand",
        "transaction_set_id",
        "date",
        "transaction_type",
        "transaction_text",
        "amount_cents",
        "vat_code",
        "account_number",
        "is_booked",
        "booked_value",
        "created_at",
        "actions",
      ];
    }

    if ("from" in this.dialogData) {
      this.from = this.dialogData.from;
      this.filters.type = this.dialogData.from;
    }

    if (this.dialogData?.contract_status) {
      this.contractStatus = this.dialogData.contract_status;
      this.isCancelled = this.contractStatus === ContractOfferStatus.CANCELED;
    }
  }

  ngAfterViewInit() {
    // Wrap inside setTimeout to remove the following error if it's opened in a modal
    // Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    setTimeout(() => {
      this.paginator.pageSize = this.layoutUtilsService.paginatorDefaultOptions.pageSize;
      this.paginator.pageSizeOptions = this.layoutUtilsService.paginatorDefaultOptions.pageSizeOptions;

      // If the user changes the sort order, reset back to the first page.
      const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      this.subscriptions.push(sortSubscription);

      const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadProductsList()))
        .subscribe();
      this.subscriptions.push(paginatorSubscriptions);

      // Filtration, bind to searchInput
      const searchButtonClickEvent = fromEvent(this.searchButton._elementRef.nativeElement, "click").pipe(
        distinctUntilChanged()
      );
      const searchBarEnterKeyEvent = fromEvent(this.searchInput.nativeElement, "keyup").pipe(
        filter((event: KeyboardEvent) => event.code === "Enter" || event.code === "NumpadEnter"),
        distinctUntilChanged()
      );
      const searchSubscription = merge(searchBarEnterKeyEvent, searchButtonClickEvent)
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.pageIndex = 0;
            this.loadProductsList();
          })
        )
        .subscribe();
      this.subscriptions.push(searchSubscription);
      this.store.pipe(select(currentUser)).subscribe(
        (user) => {
          if (user) {
            this.userId = user.id;
            let cachedPayload = JSON.parse(localStorage.getItem(`${MAT_DATA_TABLES_TRANSACTIONS}_${this.userId}`));
            if (
              (this.contract_id && cachedPayload?.type == "contract") ||
              (this.vehicle_id && cachedPayload?.type == "vehicle")
            ) {
              this.filtersState = GlobalUtilsService.getLocalStorageFilter(
                `${MAT_DATA_TABLES_TRANSACTIONS}_${this.userId}`
              );
              //==========================================================
              // show the saved input value based on the route

              this.filters.type = this.filtersState.type ?? this.filters.type;

              if (this.route.snapshot.url.length > 0) {
                this.paginator.pageIndex = this.filtersState.page - 1;
              } else {
                this.existingPagination = this.filtersState.per_page;
                this.paginator.pageSize = this.filtersState.per_page;
                this.paginator.pageIndex = 0;
              }
              //==========================================================
              this.searchInput.nativeElement.value = "";
              this.paginator.pageIndex = this.filtersState.page - 1;
              this.sort.active = this.filtersState.order_column;
              this.sort.direction = this.filtersState.order_dir;

              this.filtersState.contract_id = null;
              if (this.filtersState.contract_id && !this.contract_id) {
                const contract_id = this.filtersState.contract_id;
                this.filterTransactionsByContractId(contract_id);
              }
              this.filtersState.from_date = null;
              if (this.filtersState.from_date) {
                this.filters.from_date = this.typesUtilsService.getDateFromString(this.filtersState.from_date);
              }
              this.filtersState.to_date = null;
              if (this.filtersState.to_date) {
                this.filters.to_date = this.typesUtilsService.getDateFromString(this.filtersState.to_date);
              }
              this.filtersState.is_booked = null;
              if (this.filtersState.is_booked && this.filtersState.is_booked == 1) {
                this.filters.is_booked = "booked";
              } else if (this.filtersState.is_booked == 0) {
                this.filters.is_booked = "not_booked";
              } else this.filters.is_booked = "all";
            }
            observableOf(undefined).subscribe(() => {
              this.loadProductsList();
            });
          }
        },
        (err) => {
          observableOf(undefined).subscribe(() => {
            this.loadProductsList();
          });
        }
      );
    });
  }

  loadProductsList() {
    this.isLoadingResults = true;

    this.productsService
      .indexProduct(this.filterConfiguration())
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.total;
          this.changeDetectorRef.markForCheck();
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Return empty data if error occurs
          this.isRateLimitReached = true;
          this.changeDetectorRef.markForCheck();
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.data = data));
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): ITransactionRequestPagination {
    let filter: ITransactionRequestPagination = {
      order_column: this.sort.active,
      order_dir: this.sort.direction,
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize,
      search: this.searchInput.nativeElement.value,
    };
    if (this.filters.type) {
      filter.type = this.filters.type;
    }

    if (
      this.filters.contract &&
      this.filters.contract.contract_id &&
      this.filters.type != TransactionEntityTypes.VEHICLE
    ) {
      filter["contract_id"] = this.filters.contract.contract_id;
    }
    if (this.contract_id) {
      this.lastSavedContractId =
        this.filtersState && this.filtersState.contract_id ? this.filtersState.contract_id : null;
      if (this.unloadWindow && this.lastSavedContractId) filter["contract_id"] = this.lastSavedContractId;
      else filter["contract_id"] = this.contract_id;
    }

    if (this.filters.vehicle && this.filters.vehicle.id && this.filters.type != TransactionEntityTypes.CONTRACT) {
      filter["vehicle_id"] = this.filters.vehicle.id;
    }

    if (this.vehicle_id) {
      filter["vehicle_id"] = this.vehicle_id;
    }

    const not_booked = this.translate.instant("PAYMENTS.VIEW.NOT_BOOKED");
    const booked = this.translate.instant("PAYMENTS.VIEW.BOOKED");
    if (this.filters.is_booked == "not_booked") {
      filter["is_booked"] = 0;
      this.selectedFilters["isBooked"] = not_booked;
    } else if (this.filters.is_booked == "booked") {
      filter["is_booked"] = 1;
      this.selectedFilters["isBooked"] = booked;
    } else {
      delete filter["is_booked"];
      this.selectedFilters["isBooked"] = "";
    }

    if (this.filters.from_date) {
      filter["from_date"] = this.typesUtilsService.formatDate(this.filters.from_date, "dd-MM-yyyy");
      this.selectedFilters["from_date"] = filter["from_date"];
    } else {
      this.selectedFilters["from_date"] = "";
    }

    if (this.filters.to_date) {
      filter["to_date"] = this.typesUtilsService.formatDate(this.filters.to_date, "dd-MM-yyyy");
      this.selectedFilters["to_date"] = filter["to_date"];
    } else {
      this.selectedFilters["to_date"] = "";
    }

    if (this.filters.type == TransactionEntityTypes.CONTRACT) {
      this.selectedFilters["vehicle"] = null;
    } else {
      this.selectedFilters["contract"] = null;
    }
    this.selectedFilters["type"] = this.filters.type;

    return filter;
  }

  contractDisplayFn(contract: any): string {
    return contract ? "Kontrakt #" + contract.number : "";
  }

  vehicleDisplayFn(vehicle: any): string {
    return vehicle ? "Køretøj #" + vehicle.id : "";
  }

  filterContracts(event) {
    let q = event.target.value;
    this.filteredContracts = this.productsService.getContractsList(q).pipe(
      startWith(""),
      map((res) => this.returnedContracts(res))
    );
    if (q == "" && this.selectedFilters["contract"]) {
      this.filters.contract = null;
      this.selectedFilters["contract"] = "";
      this.loadProductsList();
    }
    this.changeDetectorRef.markForCheck();
  }

  filterVehicles(event) {
    let q = event.target.value;
    this.filteredVehicles = this.productsService.getVehiclesList(q).pipe(
      startWith(""),
      map((res) => this.returnedVehicles(res))
    );
    if (q == "" && this.selectedFilters["vehicle"]) {
      this.filters.vehicle = null;
      this.selectedFilters["vehicle"] = "";
      this.loadProductsList();
    }
    this.changeDetectorRef.markForCheck();
  }

  returnedContracts(res) {
    if (res.data && res.data.length == 0) {
      this.noContractsFound = true;
    } else this.noContractsFound = false;
    return res.data;
  }

  returnedVehicles(res) {
    if (res.data && res.data.length == 0) {
      this.noVehiclesFound = true;
    } else this.noVehiclesFound = false;
    return res.data;
  }

  contractSelected($event) {
    const contract = $event.option.value;
    this.filters.contract = contract;
    this.selectedFilters["contract"] = contract.number;
    this.loadProductsList();
  }

  vehicleSelected($event) {
    const vehicle = $event.option.value;
    this.filters.vehicle = vehicle;
    this.selectedFilters["vehicle"] = vehicle.id;
    this.loadProductsList();
  }

  filterTransactionsByContractId(contract_id) {
    this.productsService.getContractDetails(contract_id).subscribe((res) => {
      this.filters.contract = {
        id: res.data.id,
        number: res.data.number,
      };
      this.selectedFilters["contract"] = res.data.number;
      this.loadProductsList();
    });
  }

  resetFilters() {
    localStorage.removeItem(`${MAT_DATA_TABLES_TRANSACTIONS}_${this.userId}`);
    this.filtersState = null;
    this.searchInput.nativeElement.value = "";
    this.paginator.pageSize = this.layoutUtilsService.paginatorDefaultOptions.pageSize;
    this.paginator.pageIndex = 0;
    this.sort.active = "date";
    this.sort.direction = "asc";
    this.filters = {
      is_booked: "all",
      from_date: null,
      to_date: null,
      contract: null,
      vehicle: null,
      type: null,
    };
    this.selectedFilters = {};
    this.loadProductsList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    if (this.route.snapshot.url.length <= 0) {
      if (this.filtersState?.length) {
        this.filtersState.length = this.existingPagination;
      }
    }
    this.saveDatatableState();
  }
  @HostListener("window:unload", ["$event"])
  saveDatatableState() {
    if (this.contract_id) {
      this.unloadWindow = true;
    }
    localStorage.setItem(`${MAT_DATA_TABLES_TRANSACTIONS}_${this.userId}`, JSON.stringify(this.filterConfiguration()));
  }
  @HostListener("window:beforeunload", ["$event"])
  clearSearchValue() {
    if (this.filtersState?.search?.value) {
      this.filtersState.search.value = "";
    }
  }
  downloadTransactionsFiles(transactionSetId, contractId, vehicleId) {
    let id;
    let type;
    if (contractId) {
      id = contractId;
      type = "contract";
    } else {
      id = vehicleId;
      type = "vehicle";
    }
    const downloadDocumentSubscription = this.productsService.downloadTransactionsFiles(transactionSetId).subscribe(
      (res: any) => {
        res.data.forEach((document) => {
          this.documentService.downloadDocumentByUrl(document.url).subscribe({
            next: (res: Blob) => {
              saveAs(res, document.file_name);
            },
          });
        });
      },
      (err) => {
        if (err) {
          this.layoutUtilsService.showActionNotification(err.message, MessageType.Delete, 10000, true, false);
        } else if (err instanceof Blob) {
          // Make sure that the message in the file
          var myReader = new FileReader();
          myReader.addEventListener("loadend", (e) => {
            let json = e.srcElement["result"];
            if (json) {
              let data = JSON.parse(json).data;
              if (data) {
                this.layoutUtilsService.showActionNotification(data.message, MessageType.Delete, 10000, true, false);
              }
            }
          });
          myReader.readAsText(err);
        }
      }
    );
    this.subscriptions.push(downloadDocumentSubscription);
  }

  createTransaction(is_finance_vouchers, transaction_set_id?) {
    let data;
    if (this.contract_id) {
      data = {
        contract_id: this.contract_id,
        is_finance_vouchers: is_finance_vouchers,
        transaction_set_id,
      };
    } else if (this.vehicle_id) {
      data = {
        vehicle_id: this.vehicle_id,
        is_finance_vouchers: is_finance_vouchers,
        transaction_set_id,
      };
    }
    const dialogRef = this.dialog.open(TransactionEditDialogComponent, {
      data: data,
      width: "1000px",
      maxHeight: "90vh",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.loadProductsList();
    });
  }

  editTransaction(transaction) {
    let data;
    if (transaction.contract_id) {
      data = {
        contract_id: transaction.contract_id,
        is_finance_vouchers: transaction.transaction_type == TransactionTypes.FINANCE_VOUCHERS,
        transaction_set_id: transaction.set?.id,
      };
    } else if (transaction.vehicle_id) {
      data = {
        vehicle_id: transaction.vehicle_id,
        is_finance_vouchers: transaction.transaction_type == TransactionTypes.FINANCE_VOUCHERS,
        transaction_set_id: transaction.set?.id,
      };
    }
    const dialogRef = this.dialog.open(TransactionEditDialogComponent, {
      data: data,
      width: "1000px",
      maxHeight: "90vh",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.loadProductsList();
    });
  }

  prepareTransactionsData(transaction_set) {
    const transactions: any[] = [];
    for (const transaction of transaction_set) {
      transactions.push(`
			<tr>
				<td>${transaction.date || ""}</td>
				<td>${transaction.transaction_text || ""}</td>
				<td>${this.decimalPipe.transform(transaction.amount_cents, "1.0-2")}</td>
				<td>
					${
            transaction.is_booked
              ? `<span class="badge kt-bg-fill-success"> ${this.translate.instant("GENERAL.YES")}</span>`
              : `<span class="badge kt-bg-fill-default"> ${this.translate.instant("GENERAL.NO")}</span>`
          }
				</td>
			</tr>
			`);
    }
    return transactions;
  }

  prepareTransactionsTable(transactions) {
    return `
			<table class="table ellipses-overflow">
				<thead>
					<tr class="highlighted">
						<th>${this.translate.instant("TRANSACTIONS.VIEW.TABLE_COLUMNS.DATE")}</th>
						<th>${this.translate.instant("TRANSACTIONS.VIEW.TABLE_COLUMNS.TEXT")}</th>
						<th>${this.translate.instant("TRANSACTIONS.VIEW.TABLE_COLUMNS.AMOUNT")}</th>
						<th>${this.translate.instant("TRANSACTIONS.VIEW.TABLE_COLUMNS.BOOKED_STATUS")}</th>
						<th>${this.translate.instant("TRANSACTIONS.VIEW.TABLE_COLUMNS.CONTRACT_VALUE")}</th>
					</tr>
				</thead>
				<tbody>
					${transactions.join(" ")}
				</tbody>
			</table>
		`;
  }

  deleteTransactionSet(param?: { set; contract_id; vehicle_id }) {
    let transactionSetList = [param?.set.id];
    const contractId = param?.contract_id ?? this.selection.selected[0]?.contract_id;
    const vehicleId = param?.vehicle_id ?? this.selection.selected[0]?.vehicle_id;
    if (!param) {
      transactionSetList = this.selection.selected.map((transaction) => transaction.set.id);
    }
    transactionSetList = transactionSetList.filter((value, index, array) => array.indexOf(value) === index);

    let _description: string = `<div><p>${this.translate.instant("TRANSACTIONS.VIEW.DELETE_MODAL.DESCRIPTION")}</p>`;
    let transactionData = [];
    for (const transactionSetId of transactionSetList) {
      this.productsService.getTransactionsList(transactionSetId).subscribe((res: any) => {
        transactionData.push(this.prepareTransactionsData(res.transaction_lines));
        if (transactionSetList.indexOf(transactionSetId) === transactionSetList.length - 1) {
          const _title: string = this.translate.instant("TRANSACTIONS.VIEW.DELETE_MODAL.TITLE");
          _description += this.prepareTransactionsTable([...transactionData]).replace(/,/g, "");
          _description += "</div>";

          const _waitDesciption: string = this.translate.instant("TRANSACTIONS.VIEW.DELETE_MODAL.WAIT_DESCRIPTION");
          const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption, "750px");
          dialogRef.beforeClosed().subscribe((res) => {
            if (!res) {
              return;
            }
            if (contractId) {
              this.productsService.deleteTransactionSet(transactionSetList, contractId, "contract").subscribe({
                next: (res) => {
                  this.layoutUtilsService.showActionNotification(
                    this.translate.instant("TRANSACTIONS.VIEW.TITLE") + this.translate.instant("GENERAL.MODEL_DELETE"),
                    MessageType.Delete
                  );
                  this.changeDetectorRef.markForCheck();
                  this.selection.clear();
                  this.loadProductsList();
                },
                error: (err) => {
                  this.layoutUtilsService.showActionNotification(err.message, MessageType.Delete);
                },
              });
            } else {
              this.productsService.deleteTransactionSet(transactionSetList, vehicleId, "vehicle").subscribe({
                next: (res) => {
                  this.layoutUtilsService.showActionNotification(
                    this.translate.instant("TRANSACTIONS.VIEW.TITLE") + this.translate.instant("GENERAL.MODEL_DELETE"),
                    MessageType.Delete
                  );
                  this.changeDetectorRef.markForCheck();
                  this.selection.clear();
                  this.loadProductsList();
                },
                error: (err) => {
                  this.layoutUtilsService.showActionNotification(err.message, MessageType.Delete);
                },
              });
            }
          });
        }
      });
    }
  }

  reverseTransactionSet(param?: { set; contract_id; vehicle_id }) {
    let transactionSetList = [param?.set.id];
    const contractId = param?.contract_id ?? this.selection.selected[0]?.contract_id;
    const vehicleId = param?.vehicle_id ?? this.selection.selected[0]?.vehicle_id;
    if (!param) {
      transactionSetList = this.selection.selected.map((transaction) => transaction.set.id);
    }
    transactionSetList = transactionSetList.filter((value, index, array) => array.indexOf(value) === index);

    let _description: string = `<div> <p>${this.translate.instant("TRANSACTIONS.VIEW.REVERSE_MODAL.DESCRIPTION")}</p>`;
    let transactionData = [];
    for (const transactionSetId of transactionSetList) {
      this.productsService.getTransactionsList(transactionSetId).subscribe((res) => {
        transactionData.push(this.prepareTransactionsData(res.transaction_lines));
        if (transactionSetList.indexOf(transactionSetId) === transactionSetList.length - 1) {
          _description += this.prepareTransactionsTable([...transactionData]).replace(/,/g, "");
          _description += "</div>";
          console.log(transactionData);
          const _title: string = this.translate.instant("TRANSACTIONS.VIEW.REVERSE_MODAL.TITLE");
          const _waitDesciption: string = this.translate.instant("TRANSACTIONS.VIEW.REVERSE_MODAL.WAIT_DESCRIPTION");
          const _reverse: string = this.translate.instant("GENERAL.REVERSE");

          const dialogRef = this.layoutUtilsService.deleteElement(
            _title,
            _description,
            _waitDesciption,
            "750px",
            _reverse
          );
          dialogRef.beforeClosed().subscribe((res) => {
            if (!res) {
              return;
            }
            if (contractId) {
              this.productsService
                .reverseTransactionSet(transactionSetList, contractId, TransactionEntityTypes.CONTRACT)
                .subscribe(
                  (res) => {
                    let messages = "";
                    Object.keys(res.data).forEach((key) => {
                      messages += `${key}: ${res.data[key]} \n`;
                    });
                    this.layoutUtilsService.showActionNotification(messages, MessageType.Delete);
                    this.changeDetectorRef.markForCheck();
                    this.selection.clear();
                    this.loadProductsList();
                  },
                  (err) => {
                    this.layoutUtilsService.showActionNotification(err.message, MessageType.Delete);
                  }
                );
            } else if (vehicleId) {
              this.productsService
                .reverseTransactionSet(transactionSetList, vehicleId, TransactionEntityTypes.VEHICLE)
                .subscribe(
                  (res) => {
                    let messages = "";
                    Object.keys(res.data).forEach((key) => {
                      messages += `${key}: ${res.data[key]} \n`;
                    });
                    this.layoutUtilsService.showActionNotification(messages, MessageType.Delete);
                    this.changeDetectorRef.markForCheck();
                    this.selection.clear();
                    this.loadProductsList();
                  },
                  (err) => {
                    this.layoutUtilsService.showActionNotification(err.message, MessageType.Delete);
                  }
                );
            }
          });
        }
      });
    }
  }

  bookTransaction() {
    let data;
    if (this.contract_id && this.dialogData.from === "contract") {
      data = {
        contract: { id: this.contract_id },
      };
    } else {
      data = {
        asset: { id: this.vehicle_id },
      };
    }

    const dialogRef = this.dialog.open(TransactionBookDialogComponent, {
      data: { ...data, entityType: this.filters.type },
      width: "440px",
      maxHeight: "85vh",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.loadProductsList();
    });
  }

  selectTransactions($event, row) {
    this.isbookedTransactionSelected = false;
    this.isUnbookedTransactionSelected = false;

    this.data.forEach((transaction) => {
      if (transaction.set.id === row.set.id) {
        if ($event.checked) {
          this.selection.select(transaction);
        } else {
          this.selection.deselect(transaction);
        }
      }
    });

    if (this.selection.selected.length > 0) {
      this.isbookedTransactionSelected = this.selection.selected.some((transaction) => transaction.is_booked);
      this.isUnbookedTransactionSelected = this.selection.selected.some((transaction) => !transaction.is_booked);
      this.isDifferentTypesSelected =
        this.selection.selected.some((transaction) => transaction.contract_id === null) &&
        this.selection.selected.some((transaction) => transaction.contract_id !== null);
    } else {
      this.isbookedTransactionSelected = true;
      this.isUnbookedTransactionSelected = true;
    }
  }

  importTransactions() {
    const dialogRef = this.dialog.open(TransactionImportDialogComponent, {
      width: "800px",
      maxHeight: "85vh",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res) this.loadProductsList();
    });
  }
}
