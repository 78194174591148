<div class="kt-portlet datatable-view">
  <div
    *ngIf="contract_id"
    class="kt-portlet__head kt-portlet__head__custom"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "TRANSACTIONS.VIEW.TITLE" | translate }}
      </h3>
      <div class="datatable-selected-filters-wrapper">
        <div *ngIf="selectedFilters['isBooked'] != ''" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["isBooked"] }}
          </button>
          <button
            (click)="filters.is_booked = 'all'; selectedFilters['isBooked'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['from_date']" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["from_date"] }}
          </button>
          <button
            (click)="filters.from_date = ''; selectedFilters['from_date'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['to_date']" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["to_date"] }}
          </button>
          <button
            (click)="filters.to_date = ''; selectedFilters['to_date'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="kt-portlet__head-toolbar">
      <div class="datatable-search">
        <div class="search-wrapper" style="position: relative">
          <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
          <input
            placeholder="{{ 'GENERAL.SEARCH' | translate }}"
            class="form-control"
            #searchInput
            style="height: calc(1.5em + 1rem + 2px)"
          />
          <button
            #searchButton
            mat-raised-button
            style="
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            "
            color="primary"
          >
            Søg
          </button>
        </div>
      </div>
      <datatable-filter-dropdown (onReset)="resetFilters()" [filters]="filters">
        <mat-accordion>
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.BOOKED_STATUS" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-radio-group [(ngModel)]="filters.is_booked" (change)="loadProductsList()">
                <mat-radio-button value="all" [checked]="filters['is_booked'] == 'all'">{{
                  "GENERAL.ALL" | translate
                }}</mat-radio-button>
                <mat-radio-button value="booked">{{ "GENERAL.YES" | translate }} </mat-radio-button>
                <mat-radio-button value="not_booked"> {{ "GENERAL.NO" | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.DATE_FROM" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [(ngModel)]="filters.from_date"
                  (ngModelChange)="loadProductsList()"
                  [matDatepicker]="dateFromPicker"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.DATE_FROM' | translate }}"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="dateFromPicker"> </mat-datepicker-toggle>
                <mat-datepicker #dateFromPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.DATE_TO" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [(ngModel)]="filters.to_date"
                  (ngModelChange)="loadProductsList()"
                  [matDatepicker]="dateToPicker"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.DATE_TO' | translate }}"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="dateToPicker"> </mat-datepicker-toggle>
                <mat-datepicker #dateToPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </datatable-filter-dropdown>
      <div class="btn-container">
        <button
          (click)="bookTransaction()"
          class="btn btn-success kt-bg-fill-success"
          mat-raised-button
          [disabled]="isCancelled"
        >
          {{ "CONTRACTS.DETAILS.TRANSACTIONS.BOOK_TRANSACTION" | translate }}
        </button>
        <button
          *ngIf="contract_id || vehicle_id"
          (click)="createTransaction(true)"
          class="btn btn-primary mx-2"
          color="primary"
          mat-raised-button
          [disabled]="isCancelled"
        >
          {{ "CONTRACTS.DETAILS.TRANSACTIONS.CREATE_FINANCIAL_VOUCHER" | translate }}
        </button>
        <button
          *ngIf="contract_id || vehicle_id"
          (click)="createTransaction(false)"
          class="btn btn-primary"
          color="primary"
          mat-raised-button
          [disabled]="isCancelled"
        >
          {{ "CONTRACTS.DETAILS.TRANSACTIONS.CREATE_SUPPLIER_INVOICE" | translate }}
        </button>
        <button
          (click)="reverseTransactionSet()"
          [disabled]="isUnbookedTransactionSelected || isCancelled"
          class="btn btn-primary mx-2"
          color="primary"
          mat-raised-button
        >
          {{ "CONTRACTS.DETAILS.TRANSACTIONS.REVERSE_TRANSACTION" | translate }}
        </button>
        <button
          (click)="deleteTransactionSet()"
          [disabled]="isbookedTransactionSelected || isCancelled"
          class="btn btn-primary mx-2"
          color="primary"
          mat-raised-button
        >
          {{ "CONTRACTS.DETAILS.TRANSACTIONS.DELETE_TRANSACTION" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!contract_id" class="kt-portlet__head">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "TRANSACTIONS.VIEW.TITLE" | translate }}
      </h3>
      <div class="datatable-selected-filters-wrapper">
        <div *ngIf="filters.type" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["type"] }}
          </button>
          <button
            (click)="filters.type = null; selectedFilters['type'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['isBooked'] != ''" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["isBooked"] }}
          </button>
          <button
            (click)="filters.is_booked = 'all'; selectedFilters['isBooked'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['from_date']" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["from_date"] }}
          </button>
          <button
            (click)="filters.from_date = ''; selectedFilters['from_date'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['to_date']" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["to_date"] }}
          </button>
          <button
            (click)="filters.to_date = ''; selectedFilters['to_date'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['contract']" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            Kontrakt #{{ selectedFilters["contract"] }}
          </button>
          <button
            (click)="filters.contract = ''; selectedFilters['contract'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
        <div *ngIf="selectedFilters['vehicle']" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            Køretøj #{{ selectedFilters["vehicle"] }}
          </button>
          <button
            (click)="filters.vehicle = ''; selectedFilters['vehicle'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="kt-portlet__head-toolbar">
      <div class="datatable-search">
        <div class="search-wrapper" style="position: relative">
          <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
          <input
            placeholder="{{ 'GENERAL.SEARCH' | translate }}"
            class="form-control"
            #searchInput
            style="height: calc(1.5em + 1rem + 2px)"
          />
          <button
            #searchButton
            mat-raised-button
            style="
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            "
            color="primary"
          >
            Søg
          </button>
        </div>
      </div>
      <datatable-filter-dropdown (onReset)="resetFilters()" [filters]="filters">
        <mat-accordion>
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.BOOKED_STATUS" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-radio-group [(ngModel)]="filters.is_booked" (change)="loadProductsList()">
                <mat-radio-button value="all" [checked]="filters['is_booked'] == 'all'">{{
                  "GENERAL.ALL" | translate
                }}</mat-radio-button>
                <mat-radio-button value="booked">{{ "GENERAL.YES" | translate }} </mat-radio-button>
                <mat-radio-button value="not_booked"> {{ "GENERAL.NO" | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!from">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.TYPE" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-radio-group [(ngModel)]="filters.type" (change)="loadProductsList()">
                <mat-radio-button [value]="TransactionEntityTypes.CONTRACT">{{
                  "PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT" | translate
                }}</mat-radio-button>
                <mat-radio-button [value]="TransactionEntityTypes.VEHICLE">
                  {{ "PAYMENTS.VIEW.TABLE_COLUMNS.VEHICLE" | translate }}</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.DATE_FROM" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [(ngModel)]="filters.from_date"
                  (ngModelChange)="loadProductsList()"
                  [matDatepicker]="dateFromPicker"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.DATE_FROM' | translate }}"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="dateFromPicker"> </mat-datepicker-toggle>
                <mat-datepicker #dateFromPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.DATE_TO" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [(ngModel)]="filters.to_date"
                  (ngModelChange)="loadProductsList()"
                  [matDatepicker]="dateToPicker"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.DATE_TO' | translate }}"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="dateToPicker"> </mat-datepicker-toggle>
                <mat-datepicker #dateToPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="filters['type'] == 'contract'">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control position-relative">
              <mat-form-field class="example-full-width searchable">
                <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                <input
                  type="text"
                  (input)="filterContracts($event)"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT' | translate }}"
                  aria-label="Number"
                  [(ngModel)]="filters.contract"
                  matInput
                  [matAutocomplete]="auto1"
                />
                <mat-autocomplete
                  #auto1="matAutocomplete"
                  [displayWith]="contractDisplayFn"
                  (optionSelected)="contractSelected($event)"
                >
                  <mat-option *ngFor="let contract of filteredContracts | async" [value]="contract">
                    {{ "PAYMENTS.VIEW.TABLE_COLUMNS.CONTRACT" | translate }} #{{ contract.number }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <span class="no-filter-data mat-error" *ngIf="noContractsFound">{{
                "GENERAL.NO_RESULTS_FOUND" | translate
              }}</span>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="filters['type'] == 'vehicle' && !vehicle_id">
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "PAYMENTS.VIEW.TABLE_COLUMNS.VEHICLE" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control position-relative">
              <mat-form-field class="example-full-width searchable">
                <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                <input
                  type="text"
                  (input)="filterVehicles($event)"
                  placeholder="{{ 'PAYMENTS.VIEW.TABLE_COLUMNS.VEHICLE' | translate }}"
                  aria-label="Number"
                  [(ngModel)]="filters.vehicle"
                  matInput
                  [matAutocomplete]="auto1"
                />
                <mat-autocomplete
                  #auto1="matAutocomplete"
                  [displayWith]="vehicleDisplayFn"
                  (optionSelected)="vehicleSelected($event)"
                >
                  <mat-option *ngFor="let vehicle of filteredVehicles | async" [value]="vehicle">
                    {{ "PAYMENTS.VIEW.TABLE_COLUMNS.VEHICLE" | translate }} #{{ vehicle.id }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <span class="no-filter-data mat-error" *ngIf="noVehiclesFound">{{
                "GENERAL.NO_RESULTS_FOUND" | translate
              }}</span>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </datatable-filter-dropdown>
      <div class="btn-container">
        <button (click)="bookTransaction()" class="btn btn-success kt-bg-fill-success" mat-raised-button>
          {{ "CONTRACTS.DETAILS.TRANSACTIONS.BOOK_TRANSACTION" | translate }}
        </button>
        <ng-container *ngIf="!vehicle_id">
          <button (click)="importTransactions()" class="btn btn-primary" color="primary" mat-raised-button>
            {{ "CONTRACTS.DETAILS.TRANSACTIONS.BULK_IMPORTING" | translate }}
          </button>
        </ng-container>
        <button
          (click)="reverseTransactionSet()"
          [disabled]="isUnbookedTransactionSelected || isCancelled || isDifferentTypesSelected"
          class="btn btn-primary"
          color="primary"
          mat-raised-button
        >
          {{ "CONTRACTS.DETAILS.TRANSACTIONS.REVERSE_TRANSACTION" | translate }}
        </button>
        <button
          (click)="deleteTransactionSet()"
          [disabled]="isbookedTransactionSelected || isCancelled || isDifferentTypesSelected"
          class="btn btn-primary"
          color="primary"
          mat-raised-button
        >
          {{ "CONTRACTS.DETAILS.TRANSACTIONS.DELETE_TRANSACTION" | translate }}
        </button>
        <ng-container *ngIf="vehicle_id">
          <button
            (click)="createTransaction(true)"
            class="btn btn-primary"
            color="primary"
            mat-raised-button
            [disabled]="isCancelled"
          >
            {{ "CONTRACTS.DETAILS.TRANSACTIONS.CREATE_FINANCIAL_VOUCHER" | translate }}
          </button>
          <button
            (click)="createTransaction(false)"
            class="btn btn-primary"
            color="primary"
            mat-raised-button
            [disabled]="isCancelled"
          >
            {{ "CONTRACTS.DETAILS.TRANSACTIONS.CREATE_SUPPLIER_INVOICE" | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="kt-portlet__body" style="overflow: inherit">
    <div class="mat-table__wrapper datatable-container">
      <div class="datatable-table-container">
        <table
          #table
          mat-table
          [dataSource]="data"
          class="example-table mat-elevation-z8 readonly"
          matSort
          matSortActive="date"
          matSortDisableClear
          matSortDirection="asc"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="expand">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? this.selection.toggle(row) : null; selectTransactions($event, row)"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <th mat-header-cell *matHeaderCellDef class="expand-entity-header"></th>
            <td
              mat-cell
              *matCellDef="let row"
              class="expand-entity-info"
              [class.entity-expanded-row]="expandedElement === row"
            >
              <button
                class="expand-info"
                mat-icon-button
                color="primary"
                (click)="expandedElement = expandedElement === row ? null : row"
              >
                <i class="far fa-plus-square"></i>
              </button>
              <button
                mat-icon-button
                class="collapse-info"
                color="warn"
                (click)="expandedElement = expandedElement === row ? null : row"
              >
                <i class="far fa-minus-square"></i>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="transaction_set_id">
            <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header width="5%">
              {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.TRANSACTION_SET_ID" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" width="5%">
              {{ row.transaction_set_id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
              {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.DATE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" width="10%">
              {{ row.date | dmyDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="transaction_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.TYPE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ transactionTypesLabels.get(row.transaction_type) | translate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="transaction_text">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.TEXT" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              [matTooltip]="row.transaction_text"
              class="responsive curser-initial"
              width="10%"
            >
              {{ this.layoutUtilsService.sliceWord(row.transaction_text) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="amount_cents">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.AMOUNT" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.amount_cents | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="vat_code">
            <th mat-header-cell *matHeaderCellDef class="responsive" width="10%">
              {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.VAT_CODE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.vat_code?.entity_object.vatCode }}
            </td>
          </ng-container>

          <ng-container matColumnDef="account_number">
            <th mat-header-cell *matHeaderCellDef class="responsive" width="10%">
              {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.ACCOUNT_NUMBER" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.account?.entity_id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="is_booked">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.BOOKED_STATUS" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              <span class="badge kt-bg-fill-default" *ngIf="!row.is_booked">
                {{ "GENERAL.NO" | translate }}
              </span>
              <span class="badge kt-bg-fill-success" *ngIf="row.is_booked">
                {{ "GENERAL.YES" | translate }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="booked_value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.BOOKED_VALUE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.booked_value | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="contract_value">
            <th mat-header-cell *matHeaderCellDef class="responsive" width="10%">
              {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.CONTRACT_VALUE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.contract_value | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.CREATED" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              <span [innerHTML]="row.created_at | dmyDate"></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef width="5%"></th>
            <td mat-cell *matCellDef="let row" width="5%">
              <kt-actions-menu *ngIf="!isCancelled">
                <li *ngIf="!row.is_booked" (click)="editTransaction(row)" class="kt-nav__item">
                  <a href="javascript:;" class="kt-nav__link">
                    <span [inlineSVG]="'./assets/media/icons/edit.svg'"></span>
                    <span class="kt-nav__link-text">
                      {{ "GENERAL.EDIT" | translate }}
                    </span>
                  </a>
                </li>
                <li *ngIf="!row.is_booked" (click)="deleteTransactionSet(row)" class="kt-nav__item">
                  <a href="javascript:;" class="kt-nav__link">
                    <span [inlineSVG]="'./assets/media/icons/trash.svg'"></span>
                    <span class="kt-nav__link-text">
                      {{ "GENERAL.DELETE" | translate }}
                    </span>
                  </a>
                </li>
                <li *ngIf="row.is_booked" (click)="reverseTransactionSet(row)" class="kt-nav__item">
                  <a href="javascript:;" class="kt-nav__link">
                    <span>
                      <i class="fas fa-redo-alt"></i>
                    </span>
                    <span class="kt-nav__link-text">
                      {{ "GENERAL.REVERSE" | translate }}
                    </span>
                  </a>
                </li>
                <li
                  *ngIf="row.set?.transaction_set_doc?.length > 0"
                  (click)="downloadTransactionsFiles(row.transaction_set_id, row.contract_id, row.vehicle_id)"
                  class="kt-nav__item"
                >
                  <a href="javascript:;" class="kt-nav__link">
                    <span>
                      <i class="fas fa-download"></i>
                    </span>
                    <span class="kt-nav__link-text">
                      {{ "GENERAL.DOWNLOAD" | translate }}
                    </span>
                  </a>
                </li>
              </kt-actions-menu>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div
                class="entity-details-info-column"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
              >
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.TEXT" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.transaction_text }}
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.BOOKED_STATUS" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    <span class="badge kt-bg-fill-default" *ngIf="!element.is_booked">
                      {{ "GENERAL.NO" | translate }}
                    </span>
                    <span class="badge kt-bg-fill-success" *ngIf="element.is_booked">
                      {{ "GENERAL.YES" | translate }}
                    </span>
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.AMOUNT" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.amount_cents | number : "1.2-2" }}
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.CONTRACT_VALUE" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.contract_value | number : "1.2-2" }}
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.CREATED" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    <span [innerHTML]="element.created_at | dmyDate | safe"></span>
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "TRANSACTIONS.VIEW.TABLE_COLUMNS.UPDATED" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    <span [innerHTML]="element.updated_at | dmyDate | safe"></span>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="entity-default-row"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="entity-details-info-row"></tr>
        </table>
        <div *ngIf="data.length == 0 && !isLoadingResults" class="d-flex justify-content-center">
          <p class="m-3">{{ "GENERAL.NO_RESULTS_FOUND" | translate }}</p>
        </div>
        <div class="mat-table__message" *ngIf="isRateLimitReached">
          {{ "GENERAL.SOMETHING_WENT_WRONG" | translate }}
        </div>
        <!-- <div class="mat-table__message" *ngIf="isLoadingResults">{{'GENERAL.PLEASE_WAIT' | translate}}</div> -->
      </div>

      <!-- start: BOTTOM -->
      <div class="mat-table__bottom">
        <mat-spinner [diameter]="20" *ngIf="isLoadingResults"></mat-spinner>
        <mat-paginator appStylePaginator [length]="resultsLength" showFirstLastButtons></mat-paginator>
      </div>
      <!-- end: BOTTOM -->
    </div>
  </div>
  <!-- end::Body -->
</div>
