export interface IRequestPagination {
  per_page: number;
  page: number;
  order_dir?: string;
  order_column?: string;
  search?: string;
}

export class RequestPaginationModel implements IRequestPagination {
  order_dir = "desc";
  page = 1;
  per_page = 10;
  search = "";
}

export interface ITenantMessageRequestPagination extends IRequestPagination {
  message_type?: any[];
}
export interface IPaymentRequestPagination extends IRequestPagination {
  type: string;
}

export interface IInvoiceRequestPagination extends IRequestPagination {
  type: string;
}

export interface ITransactionRequestPagination extends IRequestPagination {
  type?: string;
}
