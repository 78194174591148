<div class="kt-portlet">
  <form autocomplete="off" class="kt-form">
    <div class="row align-items-center">
      <div class="col-2">
        <label class="mat-input-label">
          {{ "CREDITRO.CUSTOMER.REPRESENTITIVE" | translate }}
        </label>
      </div>
      <div class="col-3">
        <label class="mat-input-label">
          {{ "CREDITRO.CUSTOMER.NAME" | translate }}
        </label>
      </div>
      <div class="col-3">
        <label class="mat-input-label">
          {{ "CREDITRO.CUSTOMER.EMAIL" | translate }}
        </label>
      </div>
      <div class="col-2">
        <label class="mat-input-label">
          {{ "CREDITRO.CUSTOMER.STATUS" | translate }}
        </label>
      </div>
    </div>
    <mat-radio-group [formControl]="represantativeFormControl">
      <div
        class="row my-3"
        *ngFor="let customerFormGroup of customersFormArray.controls; let i = index; trackBy: trackByFn"
        [formGroup]="customerFormGroup"
      >
        <div class="col-2 align-self-center d-flex justify-content-center">
          <mat-radio-button [value]="i"> </mat-radio-button>
        </div>
        <div class="col-3 align-self-end">
          <p *ngIf="getCustomerRole(i)" class="title-label kt-font-primary">
            {{ "CREDITRO.CUSTOMER.ROLES." + getCustomerRole(i) | translate }}
          </p>
          <mat-form-field appearance="standard">
            <input
              matInput
              [placeholder]="'CREDITRO.CUSTOMER.NAME' | translate"
              type="text"
              [formControl]="customerFormGroup.controls.name"
            />
            <mat-error *ngIf="customerFormGroup.controls.name.hasError('required')">
              <strong>{{ "GENERAL.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-3 align-self-end">
          <mat-form-field class="mat-form-field-fluid" appearance="standard">
            <input
              matInput
              [placeholder]="'CREDITRO.CUSTOMER.EMAIL' | translate"
              type="text"
              [formControl]="customerFormGroup.controls.email"
            />
            <mat-error *ngIf="customerFormGroup.controls.email.hasError('required')">
              <strong>{{ "GENERAL.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
            </mat-error>
            <mat-error *ngIf="customerFormGroup.controls.email.hasError('email')">
              <strong>{{ "GENERAL.VALIDATION.EMAIL" | translate }}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-2 align-self-center">
          <ng-container *ngIf="getCustomerStatus(i) as customerStatus">
            <span
              class="badge mx-2"
              [ngClass]="{
                'kt-bg-fill-default': customerStatus === creditroCustomerStatus.EMAIL_NOT_OPENED,
                'kt-bg-fill-pending': customerStatus === creditroCustomerStatus.PENDING,
                'kt-bg-fill-success': customerStatus === creditroCustomerStatus.DONE,
                'kt-bg-fill-danger': customerStatus === creditroCustomerStatus.EMAIL_UNVERIFIED
              }"
            >
              {{ "CREDITRO.CUSTOMER.STATUSES." + customerStatus | translate }}
            </span>
          </ng-container>
        </div>
        <div *ngIf="!getCustomerRole(i) && !disableFormArray" class="col-1 align-self-center">
          <button
            type="button"
            mat-mini-fab
            color="warn"
            aria-label="button with a delete icon"
            class="m-2"
            (click)="removeCustomer(i)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </mat-radio-group>
    <div *ngIf="!disableFormArray" class="d-flex justify-content-center">
      <button type="button" mat-mini-fab color="accent" class="m-4" (click)="addCustomer()">
        <i class="fas fa-plus"></i>
      </button>
    </div>
  </form>
</div>
