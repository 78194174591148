export enum CreditroCustomerType {
  REPRESENTATIVE = "CONTACT",
  PERSON = "PERSON",
}

export enum CreditroCaseType {
  CORPORATE = "COMPANY",
  PRIVATE = "PERSON",
}

export enum CreditroCaseStatus {
  EMAIL_NOT_OPENED_BY_CONTACT = "EMAIL_NOT_OPENED_BY_CONTACT",
  PENDING_RECONFIRMATION = "PENDING_RECONFIRMATION",
  PENDING_PEOPLE = "PENDING_PEOPLE",
  PENDING_DOCUMENTS = "PENDING_DOCUMENTS",
  PENDING_INTERNAL_QUESTIONS = "PENDING_INTERNAL_QUESTIONS",
  PENDING_ANSWERS = "PENDING_ANSWERS",
  DONE = "DONE",
}

export enum CreditroCustomerStatus {
  EMAIL_UNVERIFIED = "EMAIL_UNVERIFIED",
  EMAIL_NOT_OPENED = "EMAIL_NOT_OPENED",
  PENDING = "PENDING",
  DONE = "DONE",
}

export enum CreditroCustomerRole {
  MAIN_CUSTOMER = "MAIN_CUSTOMER",
  REAL_OWNER = "REAL_OWNER",
}
